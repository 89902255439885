.page {
  height: 100%;

  :global {
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.card {
  flex: 1;

  :global {
    .card-head {
      padding-left: 64px;
      justify-content: center;
      position: relative;

      .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 90%;
        height: 100%;
      }

      .title {
        z-index: 1;
        font-size: 28px;
        line-height: 45px;
        letter-spacing: 2px;
        color: #ffffff;
      }
    }
  }
}

.data3 {
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  :global {
    .item {
      justify-content: space-between;
      align-items: center;
      position: relative;

      .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 84%;
      }

      .value {
        color: #fc3522;
      }

      .value2 {
        color: #ffe36e;
      }

      .name {
        color: #fff;
      }
    }
  }
}

.data4 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex: 1;
  align-items: center;

  :global {
    .item {
      background-image: linear-gradient(90deg,
          #cd2020 0%,
          rgba(205, 32, 32, 0) 100%);
      border-radius: 6px 0px 0px 6px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .icon {}

      .val {
        gap: 2px;

        .value {
          color: #fff;
          font-weight: bold;
        }

        .name {
          color: #fff;
        }
      }
    }
  }
}

.data5 {
  flex: 1;
  flex-direction: row;
  align-items: center;

  :global {
    .pie-chart-data-index {
      flex: 1;
      align-items: center;
      justify-content: center;

      div {
        width: 40px;
        height: 20px;
        border-radius: 4px;
      }
    }
  }
}

.data7 {
  flex: 1;

  :global {
    .header {
      .header-item {
        &:nth-child(3) {
          color: #f0ce8f;
        }
      }
    }

    .row-item {
      .ceil {
        &:nth-child(3) {
          color: #f0ce8f;
        }
      }
    }
  }
}