.Chart {
  position: relative;

  :global {
    .abs {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
    }
  }

}