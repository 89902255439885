.page {
  gap: 24px;
  background-color: #eeeeee;

  :global {
    .head {
      height: 60px;
      background-color: #f70701;
    }
    .main {
      flex: 1;
      gap: 24px;
      flex-direction: row;
      padding-bottom: 24px;
      .left {
        width: 324px;
        background-color: #fff;
        border-radius: 0 8px 8px 0;
        .item {
          height: 80px;
          padding: 0 24px;
          display: flex;
          justify-content: center;
          font-size: 24px;
          cursor: pointer;
          &.select {
            background-color: #f70701;
            color: #fff;
          }
        }
      }
      .right {
        flex: 1;
        gap: 24px;
        .top {
          border-radius: 8px;
          background-color: #fff;
          height: 126px;
        }
        .content {
          flex: 1;
          border-radius: 8px;
          background-color: #fff;
        }
      }
    }
  }
}