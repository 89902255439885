.page {
  align-items: center;
  justify-content: center;

  :global {
    .head {
      z-index: 1;
      font-size: 70px;
      letter-spacing: 7px;
      color: #fff;
      text-shadow: 0px 6px 8px 0px rgba(225, 61, 4, 0.64);
      margin-bottom: 80px;
      font-weight: bold;
    }
  }
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.login {
  z-index: 1;
  border-radius: 16px;
  background-color: #fff;
  flex-direction: row;
  overflow: hidden;

  :global {

    .left {
      width: 440px;
      height: 100%;
    }

    .right {
      width: 484px;
      padding: 60px 60px;

      .title {
        font-size: 26px;
        color: #fb6868;
        font-weight: bold;
      }

      .label {
        font-size: 21px;
        color: #333;
        font-weight: bold;
        margin-top: 32px;

        &.account {
          margin-top: 62px;
        }
      }

      input {
        width: 100%;
        height: 49px;
        border-radius: 4px;
        padding: 0 10px;
        border: solid 1px #dddddd;
        outline: none;
        margin-top: 12px;
      }

      .err {
        color: #fff;
        margin-top: 16px;

        &.show {
          color: #f44b35;
        }
      }

      .submit {
        margin-top: 40px;
        height: 49px;
        background-color: #eb4040;
        border-radius: 4px;
        color: #fff;
        font-size: 22px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}