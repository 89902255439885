.page {
  :global {
    .swiper {
      flex: 3;
      width: 100%;

      .swiper-wrapper {
        flex-direction: row;
      }

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      cursor: pointer;

      &.left {
        left: 70px;
      }

      &.right {
        right: 70px;
      }
    }
  }
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.title {
  flex: 1;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 70px;
  letter-spacing: 7px;
  color: #fff;
  text-shadow: 0px 6px 8px 0px rgba(225, 61, 4, 0.64);
  font-weight: bold;
}

.user {
  flex-direction: row;
  padding: 4px 16px;
  border-radius: 50px;
  border: 1px solid #fff;
  align-self: center;
  z-index: 1;
  align-items: center;
  gap: 80px;
  color: #fff;
  margin-top: 24px;
  :global {
    .logout {
      cursor: pointer;
    }
  }
}

.list {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 1400px;
  height: 386px;

  :global {
    .item {
      width: 320px;
      position: relative;
      align-items: center;
      justify-content: center;
      gap: 32px;
      cursor: pointer;

      .name {
        position: absolute;
        z-index: 1;
        bottom: 40px;
        left: 40px;
        right: 40px;
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #fff;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}